import React, { useState, useEffect, useRef } from 'react';
import CountUp from 'react-countup'; // For easy count-up animations
import job from '../../../assets/img/11.webp';
import ins from '../../../assets/img/12.webp';
import awd from '../../../assets/img/13.webp';
import grad from '../../../assets/img/14.webp';
import clock from '../../../assets/img/15.webp';

const ScrollTriggerCountUp = () => {
  const [isInView, setIsInView] = useState(false);
  const countUpRef = useRef(null);

  // Function to check if the element is in view
  const checkInView = () => {
    if (countUpRef.current) {
      const rect = countUpRef.current.getBoundingClientRect();
      const isInView = rect.top >= 0 && rect.bottom <= window.innerHeight;
      // Trigger count-up only once
      if (isInView && !isInViewTriggered) {
        setIsInView(true);
        setIsInViewTriggered(true); // Prevent further triggering
      }
    }
  };

  const [isInViewTriggered, setIsInViewTriggered] = useState(false);

  // Set up scroll event listener
  useEffect(() => {
    window.addEventListener('scroll', checkInView);
    checkInView(); // Check on mount in case element is already in view

    // Clean up the event listener
    return () => {
      window.removeEventListener('scroll', checkInView);
    };
  }, []);

  return (
    <div style={{ height: 'auto' , background:"#000"}}> {/* Makes the page tall enough to scroll */}
      <div
        ref={countUpRef}
        style={{
         // Position the element towards the middle of the viewport
          textAlign: 'center',
          fontSize: '50px',
        }}
      >
        {isInView && (
            <div className='mblcounter'>
                   <div className="cntr">
                <div className="left">

<div> <img src={grad} alt="count" width="90" height="90" /></div>
</div>
          <div className="right">
          <CountUp start={0} end={1000} duration={3} separator="," style={{fontSize:"40px",fontWeight:"900",color:"#fff"}} /><span style={{fontSize:"40px",fontWeight:"900",color:"#fff"}}>+</span>
          <p style={{fontSize:"20px",fontWeight:"900",color:"#fff"}}>Students</p>
          </div>
          </div>
                <div className="cntr">
                <div className="left">

<div><img src={ins}  alt="insurance" width="90" height="90" /></div>
</div>
          <div className="right">
          <CountUp start={0} end={14} duration={3} separator="," style={{fontSize:"40px",fontWeight:"900",color:"#fff"}} /><span style={{fontSize:"40px",fontWeight:"900",color:"#fff"}}>+</span>
          <p style={{fontSize:"20px",fontWeight:"900",color:"#fff"}}>Years of Exp</p>
          </div>
          </div>
          <div className="cntr">
          <div className="left">

<div> <img src={clock} alt="clock" width="90" height="90" /></div>
</div>
           <div className='right'>
           <CountUp start={0} end={200} duration={3} separator="," style={{fontSize:"40px",fontWeight:"900",color:"#fff"}}/><span style={{fontSize:"40px",fontWeight:"900",color:"#fff"}}>K+</span>
           <p style={{fontSize:"20px",fontWeight:"900",color:"#fff"}}>Mentoring <br/> Hours</p>
           </div>
           </div>
           <div className="cntr">
                <div className="left">

<div> <img src={awd} alt="award" /></div>
</div>
           <div className='right'>
           <CountUp start={0} end={30} duration={3} separator="," style={{fontSize:"40px",fontWeight:"900",color:"#fff"}}/><span style={{fontSize:"40px",fontWeight:"900",color:"#fff"}}>+</span>
           <p style={{fontSize:"20px",fontWeight:"900",color:"#fff"}}>Awards Won</p>
           </div>
           </div>
           <div className="cntr">
                <div className="left">

<div> <img src= {job} alt="count" width="90" height="90" /></div>
</div>
           <div className='right'> 
           <CountUp start={0} end={100} duration={3} separator="," style={{fontSize:"40px",fontWeight:"900",color:"#fff"}}/><span style={{fontSize:"40px",fontWeight:"900",color:"#fff"}}>%</span>
           <p style={{fontSize:"20px",fontWeight:"900",color:"#fff"}}>Placement <br/> Support</p>
           </div>
         </div>
         </div>
        )}
      </div>
    </div>
  );
};

export default ScrollTriggerCountUp;
